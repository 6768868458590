import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
	selector: 'gre-chatbot',
	templateUrl: './chatbot.component.html',
	styleUrl: './chatbot.component.scss',
	standalone: true,
})
export class ChatbotComponent implements OnInit, OnDestroy {
	private scriptElement!: HTMLScriptElement;
	isBrowser = false;

	constructor(@Inject(PLATFORM_ID) private platformId: object, private zone: NgZone) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnInit(): void {
		this.loadScript();
	}

	ngOnDestroy(): void {
		if (this.scriptElement) {
			this.scriptElement.remove();
		}
	}

	loadScript(): void {
		if (this.isBrowser) {
			this.zone.runOutsideAngular(() => {
				setTimeout(() => {
					this.scriptElement = document.createElement('script');
					this.scriptElement.id = 'hs-script-loader';
					this.scriptElement.defer = true;
					this.scriptElement.async = true;
					this.scriptElement.src = '//js-eu1.hs-scripts.com/139818938.js';
					document.head.appendChild(this.scriptElement);
				}, 20000);
			});
		}
	}
}
