import { provideCloudinaryLoader } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';

export const appConfig = {
	providers: [
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		provideRouter(routes, withViewTransitions({ skipInitialTransition: true })),
		provideClientHydration(withHttpTransferCacheOptions({ includePostRequests: true })),
		provideToastr({ autoDismiss: true, preventDuplicates: true }),
		provideAnimations(),
		CookieService,
		provideCloudinaryLoader('https://res.cloudinary.com/gorealtravel'),
	],
};
