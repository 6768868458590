import { Routes } from '@angular/router';

export const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('@pages/index-b/index-b-page.component').then((m) => m.IndexBPageComponent),
	},
	{
		path: 'trip-planner',
		loadComponent: () => import('@pages/selection-b/selection-b-page.component').then((m) => m.SelectionBPageComponent),
	},
	{
		path: 'search',
		loadComponent: () => import('@pages/index-b/index-b-page.component').then((m) => m.IndexBPageComponent),
	},
	{
		path: 'login',
		loadComponent: () => import('@pages/login-page/login-page.component').then((m) => m.LoginPageComponent),
	},
	{
		path: 'forgot-password',
		loadComponent: () =>
			import('@pages/forgot-password-page/forgot-password-page.component').then((m) => m.ForgotPasswordPageComponent),
	},
	{
		path: 'reset-password/:token',
		loadComponent: () =>
			import('@pages/reset-password-page/reset-password-page.component').then((m) => m.ResetPasswordPageComponent),
	},
	{
		path: 'new-password',
		loadComponent: () =>
			import('@pages/new-password-page/new-password-page.component').then((m) => m.NewPasswordPageComponent),
	},
	{
		path: 'help',
		loadComponent: () => import('@pages/faq-page/faq-page.component').then((m) => m.FaqPageComponent),
	},
	{
		path: 'privacy',
		loadComponent: () => import('@pages/policy-page/policy-page.component').then((m) => m.PolicyPageComponent),
	},
	{
		path: 'contact',
		loadComponent: () => import('@pages/contact-page/contact-page.component').then((m) => m.ContactPageComponent),
	},
	{
		path: 'references',
		loadComponent: () => import('@pages/review-page/review-page.component').then((m) => m.ReviewPageComponent),
	},
	{
		path: 'terms-of-use',
		loadComponent: () => import('@pages/terms-page/terms-page.component').then((m) => m.TermsPageComponent),
	},
	{
		path: 'ready',
		loadComponent: () => import('@pages/getready-page/getready-page.component').then((m) => m.GetreadyPageComponent),
	},
	{
		path: 'ready/:id',
		loadComponent: () => import('@pages/getready-page/getready-page.component').then((m) => m.GetreadyPageComponent),
	},
	{
		path: 'list',
		loadComponent: () => import('@pages/list-page/list-page.component').then((m) => m.ListPageComponent),
	},
	{
		path: 'nearby',
		loadComponent: () => import('@pages/nearby-page/nearby-page.component').then((m) => m.NearbyPageComponent),
	},
	{
		path: 'about-us',
		loadComponent: () => import('@pages/about-us/about-us.component').then((m) => m.AboutUsComponent),
	},
	{
		path: 'destinations',
		loadChildren: () => import('@routes/destination.routes').then((m) => m.DESTINATION_ROUTES),
	},
	{
		path: 'trip',
		loadChildren: () => import('@routes/trip.routes').then((m) => m.TRIP_ROUTES),
	},
	{
		path: 'tours',
		loadChildren: () => import('@routes/tours.routes').then((m) => m.TOURS_ROUTES),
	},
	{
		path: 'trips',
		loadChildren: () => import('@routes/trips.routes').then((m) => m.TRIPS_ROUTES),
	},
	{
		path: 'error',
		loadComponent: () => import('@pages/error-page/error-page.component').then((m) => m.ErrorPageComponent),
	},
	{
		path: '**',
		loadComponent: () => import('@pages/error-page/error-page.component').then((m) => m.ErrorPageComponent),
	},
];
