import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { NetworkService } from '@services/network.service';
import { CookieService } from 'ngx-cookie-service';
import { ChatbotComponent } from './chatbot/chatbot.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet, ChatbotComponent],
})
export class AppComponent implements OnInit {
	title = 'GoRealEurope';
	cookieValue = 'UNKNOWN';
	gre_ta!: string;
	cjevent!: string;
	utm_medium!: string;
	utm_email!: string;
	utm_source!: string;
	utm_campaign!: string;
	utm_term!: string;
	utm_content!: string;
	lead_form_type!: string;
	marketing_trip_name!: string;

	constructor(
		private cookieService: CookieService,
		private network: NetworkService,

		public route: ActivatedRoute,

		@Inject(PLATFORM_ID) private platformId: object,
	) {}

	onActivate() {
		if (isPlatformBrowser(this.platformId)) {
			window.scroll(0, 0);
		}
	}

	ngOnInit(): void {
		// objectFitImages();
		setTimeout(() => {
			if (isPlatformBrowser(this.platformId)) {
				const head = document.getElementsByTagName('head')[0];
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
				script.defer = true; // Add the defer attribute
				head.appendChild(script);
			}
		}, 1000);

		//this.cookieService.deleteAll();
		this.route.queryParams.subscribe((params) => {
			this.cjevent = params['cjevent'];
			this.gre_ta = params['gre_ta'];
			this.utm_medium = params['utm_medium'];
			this.utm_email = params['utm_email'];
			if (Array.isArray(params['utm_source'])) {
				this.utm_source = params['utm_source'][0];
			} else {
				this.utm_source = params['utm_source'];
			}
			this.utm_campaign = params['utm_campaign'];
			this.utm_term = params['utm_term'];
			this.utm_content = params['utm_content'];
			this.lead_form_type = params['lead_form_type'];
			this.marketing_trip_name = params['marketing_trip_name'];
			if (this.cjevent !== undefined && this.gre_ta !== undefined) {
				this.cookieService.delete('_gre_ta', '/');
				this.cookieService.delete('_cjevent', '/');
				this.cookieService.delete('_utm_medium', '/');
				this.cookieService.delete('_utm_email', '/');
				this.cookieService.delete('_utm_source', '/');
				this.cookieService.delete('_utm_campaign', '/');
				this.cookieService.delete('_utm_term', '/');
				this.cookieService.delete('_utm_content', '/');
				this.cookieService.delete('_utm_params', '/');
				this.cookieService.set('_cjevent', this.cjevent, 120, '/');
				this.cookieService.set('_gre_ta', this.gre_ta, 120, '/');
			} else if (this.cjevent !== undefined && this.gre_ta == undefined) {
				this.cookieService.delete('_gre_ta', '/');
				this.cookieService.delete('_cjevent', '/');
				this.cookieService.delete('_utm_medium', '/');
				this.cookieService.delete('_utm_email', '/');
				this.cookieService.delete('_utm_source', '/');
				this.cookieService.delete('_utm_campaign', '/');
				this.cookieService.delete('_utm_term', '/');
				this.cookieService.delete('_utm_content', '/');
				this.cookieService.delete('_utm_params', '/');
				this.cookieService.set('_cjevent', this.cjevent, 120, '/');
			} else if (this.cjevent == undefined && this.gre_ta !== undefined) {
				this.cookieService.delete('_gre_ta', '/');
				this.cookieService.delete('_cjevent', '/');
				this.cookieService.delete('_utm_medium', '/');
				this.cookieService.delete('_utm_email', '/');
				this.cookieService.delete('_utm_source', '/');
				this.cookieService.delete('_utm_campaign', '/');
				this.cookieService.delete('_utm_term', '/');
				this.cookieService.delete('_utm_content', '/');
				this.cookieService.delete('_utm_params', '/');
				this.cookieService.set('_gre_ta', this.gre_ta, 120, '/');
			} else if (
				this.utm_medium !== undefined ||
				this.utm_email !== undefined ||
				this.utm_source !== undefined ||
				this.utm_campaign !== undefined ||
				this.utm_term !== undefined ||
				this.utm_content !== undefined
			) {
				this.cookieService.delete('_gre_ta', '/');
				this.cookieService.delete('_cjevent', '/');
				this.cookieService.delete('_utm_medium', '/');
				this.cookieService.delete('_utm_email', '/');
				this.cookieService.delete('_utm_source', '/');
				this.cookieService.delete('_utm_campaign', '/');
				this.cookieService.delete('_utm_term', '/');
				this.cookieService.delete('_utm_content', '/');
				this.cookieService.delete('_utm_params', '/');
				const source: any = {};
				if (this.utm_medium !== undefined) {
					source['utm_medium'] = this.utm_medium;
					this.cookieService.set('_utm_medium', this.utm_medium, 120, '/');
				}
				if (this.utm_email !== undefined) {
					source['utm_email'] = this.utm_email;
					this.cookieService.set('_utm_email', this.utm_email, 120, '/');
				}
				if (this.utm_source !== undefined) {
					source['utm_source'] = this.utm_source;
					this.cookieService.set('_utm_source', this.utm_source, 120, '/');
				}
				if (this.utm_campaign !== undefined) {
					source['utm_campaign'] = this.utm_campaign;
					this.cookieService.set('_utm_campaign', this.utm_campaign, 120, '/');
				}
				if (this.utm_term !== undefined) {
					source['utm_term'] = this.utm_term;
					this.cookieService.set('_utm_term', this.utm_term, 120, '/');
				}
				if (this.utm_content !== undefined) {
					source['utm_content'] = this.utm_content;
					this.cookieService.set('_utm_content', this.utm_content, 120, '/');
				}
				if (this.lead_form_type !== undefined) {
					source['lead_form_type'] = this.lead_form_type;
					this.cookieService.set('lead_form_type', this.lead_form_type, 120, '/');
				}
				if (this.marketing_trip_name !== undefined) {
					source['marketing_trip_name'] = this.marketing_trip_name;
					this.cookieService.set('marketing_trip_name', this.marketing_trip_name, 120, '/');
				}
				this.cookieService.set('_utm_params', JSON.stringify(source), 120, '/');
			}
			//console.log(this.cookieService.getAll());
		});
	}
}
